@tailwind base;
@tailwind components;
@tailwind utilities;

.arrow-btn {
  @apply flex items-center justify-center w-8 h-8 l:w-auto l:h-auto border border-blue-400 text-blue-400 
    l:text-grey-600 rounded-[5px] hover:opacity-80 
    active:bg-blue-400 active:bg-opacity-10 disabled:active:bg-white disabled:active:bg-opacity-50
    disabled:border-grey-600 disabled:text-grey-600 l:border-none disabled:brightness-100 l:brightness-50
    disabled:opacity-30 disabled:hover:text-grey-600 l:hover:text-black-700;
}

.page-btn {
  @apply flex justify-center items-center border border-grey-600 l:border-none rounded-[5px] w-[22px] l:w-auto h-6 l:h-auto px-1 hover:border-black-700 hover:selection:text-black-700;
}

.custom-scroll {
  scrollbar-width: 3px;
}

.custom-scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #dddddd;
  border-radius: 12px;
  height: 4px;
  @apply bg-grey-400;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  @apply bg-grey-500;
}

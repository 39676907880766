@tailwind base;
@tailwind components;
@tailwind utilities;

.button-default {
  @apply h-8 l:h-10 px-3.5 l:px-6 flex justify-center items-center rounded-[5px] text-[12px] l:text-[14px] font-semibold transition-all whitespace-nowrap disabled:opacity-70;
}

.button-contained {
  @apply text-white !bg-blue-400 hover:brightness-110 active:brightness-75 disabled:hover:brightness-100;
}

.button-outlined {
  @apply text-blue-400 border border-blue-400 hover:bg-blue-400 hover:bg-opacity-10 active:bg-opacity-20 disabled:hover:bg-white disabled:hover:bg-opacity-100;
}

.button-outlined-black {
  @apply border text-black-700 border-black-700 hover:bg-black-700 hover:bg-opacity-10 active:bg-opacity-20 disabled:hover:bg-white disabled:hover:bg-opacity-100;
}

.button-error {
  @apply text-white !bg-error-light hover:brightness-110 active:brightness-75 disabled:hover:brightness-100;
}

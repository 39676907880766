@tailwind base;
@tailwind components;
@tailwind utilities;

table {
  @apply w-full;
}

.td-shadow {
  -webkit-box-shadow: 3px 1px 10px 0 rgba(28, 38, 54, 0.1);
  -moz-box-shadow: 3px 1px 10px 0 rgba(28, 38, 54, 0.1);
  box-shadow: 3px 1px 10px 0 rgba(28, 38, 54, 0.1);
}

.page-table thead > tr {
  @apply h-12 pl-3 pr-8 bg-grey-300;
}

.page-table th {
  @apply font-medium text-grey-500 pl-[13px] whitespace-nowrap text-[12px];
}

.page-table tbody > tr {
  @apply px-[13px] h-[51px] l:h-14 odd:bg-white even:bg-grey-200 hover:!bg-[#eeeff1];
}

.page-table td {
  @apply text-[12px] leading-[14.4px] text-black-700 pl-[13px] whitespace-nowrap;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square'),
    url('../shared/assets/fonts/EuclidSquareLight.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square'),
    url('../shared/assets/fonts/EuclidSquareRegular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square'),
    url('../shared/assets/fonts/EuclidSquareMedium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square'),
    url('../shared/assets/fonts/EuclidSquareSemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Euclid Square';
  src: local('Euclid Square'),
    url('../shared/assets/fonts/EuclidSquareBold.ttf') format('truetype');
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #131313;
}

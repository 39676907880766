.Collapsible {
  position: relative;
}

.Collapsible__trigger + .chevron {
  position: absolute;
  top: 8px;
  right: 10px;
  transition: transform 300ms;
}

.Collapsible__trigger.is-open + .chevron {
  transform: rotateZ(180deg);
}

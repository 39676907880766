@tailwind base;
@tailwind components;
@tailwind utilities;

.rdp {
  --rdp-cell-size: 18px;
  margin: 0;
}

@media (max-width: 1024px) {
  .rdp {
    --rdp-cell-size: 24px;
  }
}

.rdp-cell > .rdp-button {
  padding: 0 14px;
  @apply px-[14px] l:px-3;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  @apply text-white !bg-blue-400 rounded opacity-100;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  @apply rounded;
}

.rdp-head_row,
.rdp-row {
  @apply !bg-white;
}

.rdp-head_cell {
  font-size: 10px;
  text-transform: capitalize;
}

.rdp-row {
  @apply box-border !h-[18px];
}
